import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeIcon from '@mui/icons-material/LightMode';
import { IconButton, IconButtonProps } from '@mui/material';
import { useEffect, useState } from 'react';

import { useColorMode } from '@/components/layout/useCustomTheme';

export default function ColorSchemeToggle(props: IconButtonProps) {
  const { onClick, sx, ...other } = props;
  const { mode, toggle: toggleColorMode } = useColorMode();

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return <IconButton color="default" {...other} sx={sx} disabled />;
  }
  return (
    <IconButton
      id="toggle-mode"
      color="default"
      {...other}
      onClick={(event) => {
        toggleColorMode();
        onClick?.(event);
      }}
      sx={sx}
    >
      {mode === `dark` && <LightModeIcon />}
      {mode === `light` && <DarkModeRoundedIcon />}
    </IconButton>
  );
}
