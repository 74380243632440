'use client';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AlarmIcon from '@mui/icons-material/Alarm';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CategoryIcon from '@mui/icons-material/Category';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EventIcon from '@mui/icons-material/Event';
import FestivalIcon from '@mui/icons-material/Festival';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import Groups2Icon from '@mui/icons-material/Groups2';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import RsvpIcon from '@mui/icons-material/Rsvp';
import ShareIcon from '@mui/icons-material/Share';
import ViewListIcon from '@mui/icons-material/ViewList';
import { Box, Container, Drawer, ListItemButton, Stack } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Image from 'next/image';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { useSnackbar } from 'notistack';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import ReferralLinkFetcher from '@/components/Fetcher/ReferralLinkFetcher';
import useAuth from '@/components/hooks/useAuth';
import { WithPermissionRequired } from '@/components/hooks/useAuthed';
import useMobile from '@/components/hooks/useMobile';
import ColorSchemeToggle from '@/components/layout/ColorSchemeToggle';
import { env } from '@/env.mjs';
import PathStorage from '@/storage/PathStorage';

function ListLinkItem({
  path,
  onClick,
  icon,
  label,
}: {
  path: string;
  onClick: () => void;
  icon: ReactNode;
  label: string;
}) {
  return (
    <Link
      prefetch
      href={path}
      style={{ display: `contents`, color: `inherit` }}
    >
      <ListItemButton key={label} onClick={onClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </Link>
  );
}

export type NavProps = {
  children?: ReactNode;
};

export default function Nav(props: NavProps) {
  const { children } = props;
  const { logout } = useAuth();
  const searchParams = useSearchParams();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [ntNavOpen, setNtNavOpen] = React.useState(false);
  const isMobile = useMobile();
  const [calendarPath, setCalendarPath] = useState(`/calendar`);
  const [artistsPath, setArtistsPath] = useState(`/artists`);
  const [showsPath, setShowsPath] = useState(`/shows`);
  const [signupDraftsPath, setSignupDraftsPath] = useState(`/signup-drafts`);
  const [programmingsPath, setProgrammingsPath] = useState(`/programmings`);
  const [venuesPath, setVenuesPath] = useState(`/venues`);
  const [venueRenewalsPath, setVenueRenewalsPath] = useState(`/venues`);
  const [draftContractsPath, setDraftContractsPath] =
    useState(`/draft-contracts`);
  const [artistSamplesListUrl, setArtistSamplesListUrl] =
    useState(`/artist-samples-list`);
  const { enqueueSnackbar } = useSnackbar();
  const [settlementShowListPath, setSettlementShowListPath] =
    useState(`/shows/settlement`);

  useEffect(() => {
    setCalendarPath(PathStorage.getFullPathOrDefault(`/calendar`));
    setArtistsPath(PathStorage.getFullPathOrDefault(`/artists`));
    setShowsPath(PathStorage.getFullPathOrDefault(`/shows`));
    setProgrammingsPath(PathStorage.getFullPathOrDefault(`/programmings`));
    setSignupDraftsPath(PathStorage.getFullPathOrDefault(`/signup-drafts`));
    setSettlementShowListPath(`/shows/settlement`);
    setVenuesPath(PathStorage.getFullPathOrDefault(`/venues`));
    setVenueRenewalsPath(PathStorage.getFullPathOrDefault(`/venues/renewal`));
    setDraftContractsPath(PathStorage.getFullPathOrDefault(`/draft-contracts`));
    setArtistSamplesListUrl(
      PathStorage.getFullPathOrDefault(`/artist-samples-list`),
    );
  }, [searchParams]);

  const drawerWidth = 240;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = useCallback(() => {
    if (isMobile) {
      setMobileOpen(false);
    }
  }, [isMobile]);

  const drawer = (
    <>
      <Toolbar>
        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Link
            prefetch
            href="/"
            style={{ display: `contents`, color: `inherit` }}
          >
            <Stack spacing="8px" direction="row" alignItems="center">
              <Box
                width={20}
                height={20}
                sx={(theme) => ({
                  '& img': {
                    filter:
                      theme.palette.mode === `dark`
                        ? `invert(100%) sepia(50%) saturate(500%)`
                        : undefined,
                  },
                })}
              >
                <Image
                  src="/images/logo.png"
                  alt="logo"
                  width={20}
                  height={20}
                  style={{
                    maxWidth: `100%`,
                    height: `auto`,
                  }}
                />
              </Box>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  fontWeight: `bold`,
                  flexGrow: 1,
                }}
                color={
                  !env.NEXT_PUBLIC_BACKEND_URL.includes(`app.gigfinesse.com`)
                    ? `error.main`
                    : `text.primary`
                }
              >
                Euterpe
              </Typography>
            </Stack>
          </Link>
          <ColorSchemeToggle />
        </Stack>
      </Toolbar>

      <Divider />
      <List>
        <WithPermissionRequired
          when={({ permissions }) => permissions.viewCalendar}
        >
          <ListLinkItem
            path={calendarPath}
            onClick={handleClick}
            icon={<EventIcon />}
            label="Calendar"
          />
        </WithPermissionRequired>
        <WithPermissionRequired
          when={({ permissions }) => permissions.viewShows}
        >
          <ListLinkItem
            path={showsPath}
            onClick={handleClick}
            icon={<ViewListIcon />}
            label="Shows"
          />
        </WithPermissionRequired>

        <WithPermissionRequired
          when={({ permissions }) => permissions.viewArtists}
        >
          <ListLinkItem
            path={artistsPath}
            onClick={handleClick}
            icon={<FormatListNumberedIcon />}
            label="Artists"
          />
        </WithPermissionRequired>
        <WithPermissionRequired
          when={({ permissions }) => permissions.viewSignupDrafts}
        >
          <ListLinkItem
            path={signupDraftsPath}
            onClick={handleClick}
            icon={<PendingActionsIcon />}
            label="Signup Drafts"
          />
        </WithPermissionRequired>
        <WithPermissionRequired
          when={({ permissions }) => permissions.viewSettlements}
        >
          <ListLinkItem
            path={settlementShowListPath}
            onClick={handleClick}
            icon={<AccountBalanceWalletIcon />}
            label="Settlement"
          />
        </WithPermissionRequired>
        <WithPermissionRequired
          when={({ permissions }) => permissions.viewProgrammings}
        >
          <ListLinkItem
            path={programmingsPath}
            onClick={handleClick}
            icon={<DateRangeIcon />}
            label="Programmings"
          />
        </WithPermissionRequired>
        <ReferralLinkFetcher>
          {({ referralLink }) =>
            referralLink ? (
              <CopyToClipboard
                text={referralLink}
                options={{ format: `text/plain` }}
                onCopy={() => {
                  enqueueSnackbar(`Copied!`);
                }}
              >
                <ListItemButton
                  key="Referral Link"
                  onClick={() => {
                    if (isMobile) {
                      setMobileOpen(false);
                    }
                  }}
                >
                  <ListItemIcon>
                    <ShareIcon />
                  </ListItemIcon>
                  <ListItemText primary="Referral Link" />
                </ListItemButton>
              </CopyToClipboard>
            ) : null
          }
        </ReferralLinkFetcher>
      </List>
      <Divider />
      <List>
        <ListLinkItem
          path="/me"
          onClick={handleClick}
          icon={<AccountBoxIcon />}
          label="Me"
        />
        <WithPermissionRequired
          when={({ permissions }) => permissions.viewVenues}
        >
          <ListLinkItem
            path={venuesPath}
            onClick={handleClick}
            icon={<FestivalIcon />}
            label="Venues"
          />
        </WithPermissionRequired>
        <WithPermissionRequired
          when={({ permissions }) => permissions.viewVenues}
        >
          <ListLinkItem
            path={venueRenewalsPath}
            onClick={handleClick}
            icon={<AlarmIcon />}
            label="Venue Renewals"
          />
        </WithPermissionRequired>
        <WithPermissionRequired
          when={({ permissions }) => permissions.viewArtistSamples}
        >
          <ListLinkItem
            path={artistSamplesListUrl}
            onClick={handleClick}
            icon={<Groups2Icon />}
            label="Artist Samples"
          />
        </WithPermissionRequired>

        <WithPermissionRequired
          when={({ permissions }) => permissions.viewArtistInvitations}
        >
          <ListLinkItem
            path="/artist-invitations"
            onClick={handleClick}
            icon={<RsvpIcon />}
            label="Artist Invitations"
          />
        </WithPermissionRequired>
        <WithPermissionRequired
          when={({ permissions }) => permissions.viewDraftContract}
        >
          <ListLinkItem
            path={draftContractsPath}
            onClick={handleClick}
            icon={<AssignmentIcon />}
            label="Draft Contracts"
          />
        </WithPermissionRequired>
        <WithPermissionRequired
          when={({ permissions }) => permissions.viewNtRecs}
        >
          <ListLinkItem
            path="/nt-rec/whitelisting"
            onClick={handleClick}
            icon={<PlaylistAddIcon />}
            label="NtRecWhitelisting"
          />
        </WithPermissionRequired>
        <ListLinkItem
          path="/posters/bb/categories"
          onClick={handleClick}
          icon={<CategoryIcon />}
          label="Bannerbear groups"
        />
        <ListItemButton
          key="Logout"
          onClick={() => {
            logout();

            if (isMobile) {
              setMobileOpen(false);
            }
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </>
  );

  return (
    <>
      <Box sx={{ display: `flex` }}>
        <AppBar
          position="fixed"
          sx={{
            display: {
              sm: `none`,
            },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: `none` } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
          }}
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: `block`, sm: `none` },
              '& .MuiDrawer-paper': {
                boxSizing: `border-box`,
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: `none`, sm: `block` },
              '& .MuiDrawer-paper': {
                boxSizing: `border-box`,
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: {
              xs: `100%`,
              sm: `calc(100% - ${drawerWidth}px)`,
            },
          }}
        >
          <Toolbar />
          {isMobile && <Box height={30} />}
          <Container maxWidth="xl">{children}</Container>
          <Box height={100} />
        </Box>
      </Box>
    </>
  );
}
