'use client';

import createCache from '@emotion/cache';
import { Options } from '@emotion/cache/dist/declarations/types';
import { CacheProvider } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { grey } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  PaletteOptions,
  ThemeProvider,
} from '@mui/material/styles';
import { dark, light } from '@mui/material/styles/createPalette';
import { useServerInsertedHTML } from 'next/navigation';
import React from 'react';
import { useLocalStorage } from 'react-use';

const theme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
  components: {
    MuiListItemButton: {
      defaultProps: {
        classes: {
          selected: `#f9820b`,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        color: `text.primary`,
      },
    },
  },
});

const lightModePalette: PaletteOptions = {
  ...light,
  mode: `light`,
  primary: theme.palette.augmentColor({
    color: {
      main: `#1976d2`,
    },
    name: `primary`,
  }),
  secondary: theme.palette.augmentColor({
    color: {
      main: `#9c27b0`,
    },
    name: `secondary`,
  }),
  error: theme.palette.augmentColor({
    color: {
      main: `#d32f2f`,
    },
    name: `error`,
  }),
  warning: theme.palette.augmentColor({
    color: {
      main: `#ed6c02`,
    },
    name: `warning`,
  }),
  info: theme.palette.augmentColor({
    color: {
      main: `#0288d1`,
    },
    name: `info`,
  }),
  success: theme.palette.augmentColor({
    color: {
      main: `#2e7d32`,
    },
    name: `success`,
  }),
  text: {
    primary: `rgba(0, 0, 0, 0.87)`,
    secondary: `rgba(0, 0, 0, 0.6)`,
    disabled: grey[900],
  },
  ochre: theme.palette.augmentColor({
    color: {
      main: `#E3D026`,
    },
    name: `ochre`,
  }),
  orange: theme.palette.augmentColor({
    color: {
      main: `#FF5C00`,
    },
    name: `orange`,
  }),
  disabled: theme.palette.augmentColor({
    color: {
      main: `#9E9E9E`,
    },
    name: `grey`,
  }),
  white: theme.palette.augmentColor({
    color: {
      main: `#ffffff`,
    },
    name: `white`,
  }),
};

const darkModePalette: PaletteOptions = {
  ...lightModePalette,
  ...dark,
  mode: `dark`,
  background: {
    default: `#121212`,
    paper: `#121212`,
  },
  divider: `rgba(255, 255, 255, 0.12)`,
  text: {
    primary: grey[50],
    secondary: grey[200],
    disabled: `#fff`,
  },
};

export type ColorModeContext = {
  mode: 'light' | 'dark';
  toggle: () => void;
};

const ColorModeContext = React.createContext<ColorModeContext | null>(null);

export function useColorMode() {
  const colorMode = React.useContext(ColorModeContext);
  if (!colorMode) {
    throw new Error(`colorMode must be used within ColorModeContext`);
  }
  return colorMode;
}

export default function CustomThemeProvider(props: {
  options: Options;
  children?: React.ReactNode;
}) {
  const { options, children } = props;
  const prefersDark = useMediaQuery(`(prefers-color-scheme: dark)`);
  const [storedMode, setStoredMode] = useLocalStorage(
    `color-mode`,
    prefersDark ? `dark` : `light`,
    { raw: true },
  );

  const [mode, setMode] = React.useState<'light' | 'dark'>(
    storedMode === `light` || storedMode === `dark` ? storedMode : `light`,
  );

  const colorMode = React.useMemo<ColorModeContext>(
    () => ({
      mode,
      toggle: () => {
        setMode(mode === `light` ? `dark` : `light`);
        setStoredMode(mode === `light` ? `dark` : `light`);

        document.body.classList.add(mode === `light` ? `dark` : `light`);
        document.body.classList.remove(mode === `light` ? `light` : `dark`);
      },
    }),
    [mode, setStoredMode],
  );

  const coloredTheme = React.useMemo(
    () =>
      createTheme({
        ...theme,
        palette: {
          mode,
          ...(mode === `light` ? lightModePalette : darkModePalette),
        },
      }),
    [mode],
  );

  const [{ cache, flush }] = React.useState(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const cache = createCache(options);
    cache.compat = true;
    const prevInsert = cache.insert;
    let inserted: string[] = [];
    cache.insert = (...args) => {
      const serialized = args[1];
      if (cache.inserted[serialized.name] === undefined) {
        inserted.push(serialized.name);
      }
      return prevInsert(...args);
    };
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const flush = () => {
      const prevInserted = inserted;
      inserted = [];
      return prevInserted;
    };
    return { cache, flush };
  });

  useServerInsertedHTML(() => {
    const names = flush();
    if (names.length === 0) {
      return null;
    }
    let styles = ``;
    // eslint-disable-next-line no-restricted-syntax
    for (const name of names) {
      styles += cache.inserted[name];
    }
    return (
      <style
        key={cache.key}
        data-emotion={`${cache.key} ${names.join(` `)}`}
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{
          __html: styles,
        }}
      />
    );
  });

  return (
    <ColorModeContext.Provider value={colorMode}>
      <CacheProvider value={cache}>
        <ThemeProvider theme={coloredTheme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </CacheProvider>
    </ColorModeContext.Provider>
  );
}
